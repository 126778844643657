const report = document.querySelector(".report");

async function validateServer(){
    report.classList.remove("hidden");
    report.innerHTML = "<h3>Connection Report: </h3>";

    let serverURL = document.querySelector("[name=serverName]").value;
    let validationJSON = await (await fetch("https://federationtester.matrix.org/api/report?server_name=" + serverURL)).json()

    if(validationJSON["DNSResult"]["Addrs"] == null){
        report.innerHTML += "<span class=\"errorLabel\">Failed to resolve server IP, please check your domain and try again</span>";
        return;
    }
    if(Object.keys(validationJSON["ConnectionReports"]).length === 0){
        report.innerHTML += "<span class=\"errorLabel\">Failed to connect to your Matrix server, please check, if your server is started and reachable.</span>";
        return;
    }

    Object.keys(validationJSON["ConnectionReports"]).forEach((serverIP) => {
        report.innerHTML += `
        <h4>${serverIP}</h4>
        <div class="row">
        <span class="label">ServerName</span>
        <span class="status ${getLabelClass(validationJSON["ConnectionReports"][serverIP]["Checks"]["MatchingServerName"])}">${getLabelText(validationJSON["ConnectionReports"][serverIP]["Checks"]["MatchingServerName"])}</span>
        </div><br>
        <div class="row">
        <span class="label">Valid until TS is in future</span>
        <span class="status ${getLabelClass(validationJSON["ConnectionReports"][serverIP]["Checks"]["FutureValidUntilTS"])}">${getLabelText(validationJSON["ConnectionReports"][serverIP]["Checks"]["FutureValidUntilTS"])}</span>
        </div><br>
        <div class="row">
        <span class="label">ED25519 Key</span>
        <span class="status ${getLabelClass(validationJSON["ConnectionReports"][serverIP]["Checks"]["HasEd25519Key"])}">${getLabelText(validationJSON["ConnectionReports"][serverIP]["Checks"]["HasEd25519Key"])}</span>
        </div><br>
        <div class="row">
        <span class="label">Ed25519 checks are OK</span>
        <span class="status ${getLabelClass(validationJSON["ConnectionReports"][serverIP]["Checks"]["AllEd25519ChecksOK"])}">${getLabelText(validationJSON["ConnectionReports"][serverIP]["Checks"]["AllEd25519ChecksOK"])}</span>
        </div><br>
        <div class="row">
        <span class="label">Certificate</span>
        <span class="status ${getLabelClass(validationJSON["ConnectionReports"][serverIP]["Checks"]["ValidCertificates"])}">${getLabelText(validationJSON["ConnectionReports"][serverIP]["Checks"]["ValidCertificates"])}</span>
        </div><br>
        `;
    });
    report.innerHTML += `
    <span class="serverVersion">Detected Matrix Version: ${validationJSON["Version"]["version"]} (${validationJSON["Version"]["name"]})</span>
`;


}

function getLabelText(testResult){
    return testResult? "Success" : "Fail"
}
function getLabelClass(testResult){
    return testResult? "success" : "error"
}

document.querySelector("#checkServerButton").onclick = () => validateServer();